import { ref } from "vue";
import { getArtistAnalytics } from "../../api/artists.api";

export const Status = {
  IDLE: "IDLE",
  RUNNING: "RUNNING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export default async function useSpotifyMonthlyListeners(uuid) {
  async function fetchSpotifyData() {
    status.value = Status.RUNNING;
    try {
      const { data } = await getArtistAnalytics(
        uuid,
        12,
        true,
        "spotify/listeners"
      );
      status.value = Status.SUCCESS;
      return {
        monthlyListeners: data.yAxis,
        months: data.xAxis,
      };
    } catch (e) {
      status.value = Status.ERROR;
      throw new Error(e);
    }
  }
  const status = ref(Status.IDLE);
  const spotifyData = ref(await fetchSpotifyData());

  return { spotifyData, status };
}
