<template>
  <div
    class="card mb-5"
    v-if="currentStatus === Status.IDLE || currentStatus === Status.RUNNING"
  >
    <div class="card-body">
      <el-skeleton animated :rows="10" />
    </div>
  </div>
  <div v-else-if="currentStatus === Status.SUCCESS && spotifyListeners.length">
    <EstimatedRevenues
      @revenueUpdated="revenueUpdated"
      :spotify-listeners="spotifyListeners"
      :spotify-listeners-months="spotifyListenersMonth"
    />
    <Forecast
      title="Artist Forecast"
      :revenueLast12Months="revenueLast12Months"
      :current-spotify-followers="currentSpotifyMonthlyListeners"
      :spotify-followers-a-year-ago="yearBackSpotifyMonthlyListeners"
    />
  </div>
  <h4 v-else class="heading text-center">Spotify data not Available</h4>
</template>

<script>
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";

import EstimatedRevenues from "../components/EstimatedRevenues";
import Forecast from "../../common/components/Forecast";
import useSpotifyMonthlyListeners, {
  Status,
} from "../../common/composables/useSpotifyMonthlyListeners";
export default {
  name: "ArtistForecast",
  components: { EstimatedRevenues, Forecast },
  setup() {
    const route = useRoute();
    const currentSpotifyMonthlyListeners = ref(0);
    const currentStatus = ref(Status.IDLE);
    const isSpotifyDataAvailable = ref(true);
    const loading = ref(true);
    const spotifyListeners = ref([]);
    const yearBackSpotifyMonthlyListeners = ref(0);
    const spotifyListenersMonth = ref([]);
    const revenueLast12Months = ref(0);

    const revenueUpdated = (value) => {
      revenueLast12Months.value = value;
    };

    onMounted(async () => {
      const { spotifyData, status } = await useSpotifyMonthlyListeners(
        route.params.uuid
      );
      if (
        spotifyData &&
        spotifyData.value &&
        spotifyData.value.monthlyListeners.length &&
        spotifyData.value.months.length > 12
      ) {
        spotifyData.value.monthlyListeners.splice(0, 1);
        spotifyData.value.months.splice(0, 1);
      }
      if (spotifyData.value.monthlyListeners.length) {
        const lastElementIndex = spotifyData.value.monthlyListeners.length - 1;
        currentSpotifyMonthlyListeners.value =
          spotifyData.value.monthlyListeners[lastElementIndex];
        yearBackSpotifyMonthlyListeners.value =
          spotifyData.value.monthlyListeners[0];
      }
      spotifyListeners.value = spotifyData.value.monthlyListeners;
      spotifyListenersMonth.value = spotifyData.value.months;
      currentStatus.value = status.value;
    });

    return {
      loading,
      isSpotifyDataAvailable,
      currentSpotifyMonthlyListeners,
      yearBackSpotifyMonthlyListeners,
      spotifyListeners,
      currentStatus,
      Status,
      spotifyListenersMonth,
      revenueLast12Months,
      revenueUpdated,
    };
  },
};
</script>

<style scoped></style>
