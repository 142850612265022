<template>
  <el-row :gutter="20" class="mb-5">
    <el-col v-for="(data, index) in revenueCardData" :key="index" :span="4">
      <div class="card text-center h-100">
        <div class="card-body">
          <h3 :class="`text-${data.color}`">
            € {{ data.value.toLocaleString("de-DE") }}
          </h3>
          <span class="subheading">{{ data.description }}</span>
        </div>
      </div>
    </el-col>
  </el-row>
  <CardToolbar
    description="Estimation based on Spotify Listeners"
    title="Revenues past 12 months"
  >
    <template v-slot:toolbar>
      <button
        class="
          btn btn-sm btn-color-muted btn-active
          active
          btn-active-primary
          px-4
        "
        data-bs-toggle="modal"
        data-bs-target="#revenues_forecast_modal"
        @click="resetData"
      >
        Configure
      </button>
    </template>
    <template v-slot:body>
      <RevenueChart
        v-if="forecastValuesConfigured"
        :currency-values="true"
        :colors="['#5AD1A6', '#26C3FD', '#D54E8C']"
        :categories="revenueGraphCategories"
        :series="revenueGraphData"
        :stroke-width="[5, 0, 5]"
      />
    </template>
  </CardToolbar>
  <ConfigureForecastModal
    @forecast-config="calculateEstimatedRevenue"
    :forecast-configuration-values="forecastConfigurationValues"
    id="revenues_forecast_modal"
    :scenarios="scenarios"
  />
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";

import CardToolbar from "../../common/components/CardToolbar";
import ConfigureForecastModal from "../../common/components/ConfigureForecastModal";

import RevenueChart from "../../common/components/RevenueChart";
export default defineComponent({
  name: "EstimatedRevenues",
  components: { RevenueChart, CardToolbar, ConfigureForecastModal },
  props: {
    spotifyListeners: {
      type: Array,
      required: true,
    },
    spotifyListenersMonths: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const forecastConfigurationValues = ref({
      averagePlays: {
        title: "Average number of play per monthly listener",
        type: "averagePlays",
        HigherBoundRange: 2.8,
        RevenueLast12Months: 2.2,
        LowerBoundRange: 1.6,
        min: 0,
        max: 10,
        step: 0.2,
      },
      spotifyShare: {
        title: "Market Share spotify vs. other Streamers",
        type: "spotifyShare",
        HigherBoundRange: 35,
        RevenueLast12Months: 40,
        LowerBoundRange: 50,
        min: 1,
        max: 100,
      },
      streamingShare: {
        title: "Market Share Streaming vs. Total Market",
        type: "streamingShare",
        HigherBoundRange: 75,
        RevenueLast12Months: 80,
        LowerBoundRange: 90,
        min: 1,
        max: 100,
      },
    });
    const forecastValuesConfigured = ref(false);
    const store = useStore();
    const revenueCardData = ref([]);
    const revenueGraphData = ref([]);
    const revenueGraphCategories = ref([]);
    const scenarios = ref([
      "Higher Bound Range",
      "Revenue Last 12 Months",
      "Lower Bound Range",
    ]);

    onMounted(async () => {
      calculateEstimatedRevenue({
        scenarios: scenarios.value,
        forecastConfig: forecastConfigurationValues.value,
      });
    });

    const configureCardColor = (scenario) => {
      if (scenario.toLowerCase().includes("higher")) {
        return "success";
      } else if (scenario.toLowerCase().includes("lower")) {
        return "danger";
      } else {
        return "primary";
      }
    };

    const calculateEstimatedRevenue = ({ scenarios, forecastConfig }) => {
      scenarios.forEach((config) => {
        const scenarioName = config.replace(/\s/g, "");
        const configValues = {
          averagePlays: forecastConfig["averagePlays"][scenarioName],
          spotifyShare: forecastConfig["spotifyShare"][scenarioName],
          streamingShare: forecastConfig["streamingShare"][scenarioName],
        };
        const totalRevenue = calculateLast12MonthsRevenue(configValues, config);
        if (scenarioName === "RevenueLast12Months") {
          context.emit("revenueUpdated", totalRevenue);
        }
        revenueCardData.value.push({
          value: Math.round(totalRevenue / 12),
          description: "MONTHLY AVG",
          color: configureCardColor(config),
        });
      });
      forecastValuesConfigured.value = true;
    };

    const calculateLast12MonthsRevenue = (forecastValue, scenario) => {
      const data = props.spotifyListeners.map((totalYearlyFollowers) => {
        const totalSpotifyPlays =
          totalYearlyFollowers * forecastValue.averagePlays;
        const revenueSpotify = totalSpotifyPlays * 0.003;
        const revenuesAllStreamingPlatforms =
          (revenueSpotify / forecastValue.spotifyShare) * 100;
        const totalRevenue =
          (revenuesAllStreamingPlatforms / forecastValue.streamingShare) * 100;
        return Math.round(totalRevenue);
      });
      const totalRevenue = data.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
      if (scenario.toLowerCase() === "revenue last 12 months") {
        store.dispatch("ArtistModule/setLastYearRevenues", totalRevenue);
      }
      revenueCardData.value.push({
        value: totalRevenue,
        description: "TOTAL REVENUE",
        color: configureCardColor(scenario),
      });
      revenueGraphCategories.value = props.spotifyListenersMonths;
      revenueGraphData.value.push({
        name: scenario,
        type: scenario === "Revenue Last 12 Months" ? "bar" : "line",
        data,
      });
      return totalRevenue;
    };

    const resetData = () => {
      forecastValuesConfigured.value = false;
      revenueGraphData.value = [];
      revenueCardData.value = [];
      revenueGraphCategories.value = [];
    };

    return {
      calculateEstimatedRevenue,
      forecastConfigurationValues,
      forecastValuesConfigured,
      revenueCardData,
      revenueGraphData,
      revenueGraphCategories,
      resetData,
      scenarios,
    };
  },
});
</script>

<style scoped></style>
